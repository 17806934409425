import { memo } from "react";

const SearchSVG = memo(function SearchSVG() {
  return <div className="leftbtn"></div>;
});

const RightBtn = memo(function ({ className = "" }) {
  return <div className={`rightbtn ${className}`}></div>;
});

const GeolocateSVG = memo(function () {
  return (
    <svg viewBox="0 0 193 193" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M96.5 66C55.8333 66 55.8333 127 96.5 127C137.167 127 137.167 66 96.5 66Z"
        fill="white"
      />
      <path
        d="M165.723 115.992H165.99L166.257 115.974C172.19 115.577 177.168 113.126 180.553 109.094C183.842 105.177 185.174 100.312 184.982 95.7573C184.6 86.6983 177.811 76.9937 165.531 76.9937H165.516H163.604C156.953 54.0922 138.921 36.0639 116.012 29.4179C115.912 26.8824 115.612 23.982 114.831 21.3356C113.916 18.235 112.189 14.7279 108.869 12.0275C105.472 9.26376 101.213 8 96.4815 8C91.7622 8 87.5086 9.25153 84.1091 12.0006C80.7832 14.6902 79.0485 18.1891 78.1283 21.2906C77.3389 23.9509 77.0366 26.8696 76.9372 29.4209C54.0325 36.0759 35.9997 54.1006 29.3573 77.0023C28.8595 77.0022 28.4419 77.0029 28.0903 77.0049C26.7358 77.0126 25.864 77.0368 24.7917 77.1882C0.74674 80.4815 3.30331 116.006 27.4465 116.006H29.3588C36.0106 138.911 54.0477 156.942 76.9621 163.585C77.0648 166.168 77.3741 169.12 78.1827 171.809C79.1127 174.902 80.856 178.378 84.1774 181.044C87.5685 183.767 91.7991 185 96.4815 185C101.191 185 105.431 183.74 108.819 180.992C112.128 178.309 113.863 174.822 114.789 171.731C115.591 169.059 115.903 166.138 116.01 163.584C138.924 156.932 156.965 138.901 163.607 115.992H165.723ZM127.351 96.5C127.351 107.156 123.404 114.573 117.914 119.413C112.272 124.387 104.512 127.051 96.4815 127.051C88.4508 127.051 80.6909 124.387 75.0488 119.413C69.5587 114.573 65.6114 107.156 65.6114 96.5C65.6114 85.8435 69.5587 78.4274 75.0488 73.5874C80.6909 68.6133 88.4508 65.9486 96.4815 65.9486C104.512 65.9486 112.272 68.6133 117.914 73.5874C123.404 78.4274 127.351 85.8435 127.351 96.5Z"
        fill="black"
        stroke="white"
        strokeWidth="13"
      />
      <path d="M96.5 74C66.5 74 66.5 119 96.5 119C126.5 119 126.5 74 96.5 74Z" fill="black" />
    </svg>
  );
});

const RecenterSVG = memo(function () {
  return (
    <svg viewBox="0 0 188 188" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 8H8V16V86V94H16H52H60V86V60H86H94V52V16V8H86H16Z"
        fill="black"
        stroke="white"
        strokeWidth="13"
      />
      <path
        d="M16 94H8V102V172V180H16H86H94V172V136V128H86H60V102V94H52H16Z"
        fill="black"
        stroke="white"
        strokeWidth="13"
      />
      <path
        d="M180 102V94H172H136H128V102V128H102H94V136V172V180H102H172H180V172V102Z"
        fill="black"
        stroke="white"
        strokeWidth="13"
      />
      <path
        d="M102 8H94V16V52V60H102H128V86V94H136H172H180V86V16V8H172H102Z"
        fill="black"
        stroke="white"
        strokeWidth="13"
      />
    </svg>
  );
});

export { SearchSVG, RightBtn, GeolocateSVG, RecenterSVG };
